import React, { useEffect, useState }  from "react";
import LongContent from "./LongContent";
import { markdownToReact } from '../util/mdToReact';


function LegalTerms(props) {
  const [content, setContent] = useState(null);

  useEffect(() => {
    // For demonstration purposes, fetch markdown content from a file.
    // You can fetch your markdown content from an API, CMS, etc.
    fetch('/md/term.md')
      .then(res => res.text())
      .then(markdownContent => {
        const reactContent = markdownToReact(markdownContent);
        setContent(reactContent);
      });
  }, []);

  return (
    <LongContent>
      {content}
    </LongContent>
  );
}

export default LegalTerms;
