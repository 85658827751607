import React, { useState } from "react";
import {
  Grid,
  Box,
  Typography,
  Tooltip,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import { useRouter } from "./../util/router";
import { makeStyles } from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";
import { useAuth } from "./../util/auth";
import { apiRequest } from "./../util/util";
import { isSubscribed } from "./../util/util";

const config = {
  imageUrl: process.env.REACT_APP_PCM_STORE_IMAGE_URL,
};


const useStyles = makeStyles((theme) => ({
  boxStyle: {
    position: "absolute",
    bottom: 0,
    width: "100%",
    backgroundColor:
      theme.palette.type === "light"
        ? "rgba(255,255,255,0.9)"
        : "rgba(0,0,0,0.7)", // Example dark theme color
    padding: theme.spacing(2),
    borderRadius: "0 0 15px 15px",
    transition: "opacity .5s",
    flexDirection: "column",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const BooksGrid = ({ books, viewOnly }) => {
  const router = useRouter();
  const [open, setOpen] = useState(false);
  const [selectedBook, setSelectedBook] = useState(null);
  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success", // or "error"
  });
  const classes = useStyles();
  const auth = useAuth();

  //TODO: use default sub books bundle, this is temp solution to fill user's bookshelf
  const subBooks = [...new Set([...auth?.user?.addedSubscritionBooks||[], ...["6d5de5c7-afd0-4995-94c5-dea175e899fc","afcc23be-5cc6-424f-83ef-0afb39cbb90e","8e28a441-7be5-40b0-a9b5-f207a8977197","a89105f2-55a5-434f-b8bf-85ec706948b7","a2df51da-bb11-451a-be03-f0b0007be015"]])];


  const handleOpen = (book) => {
    setSelectedBook(book);
    setOpen(true);
  };

  const handleClose = () => {
    setSelectedBook(null);
    setOpen(false);
  };

  const onPurchase = (book) => {
    if (auth?.user?.purchasedBooks?.includes(book.attributes?.bookId)) {
      alert("You already purchased it");
      return;
    }

    setLoading(true); // Start loading state

    // Simulate an async process, like a purchase API call
    setTimeout(() => {
      console.log("Purchased book: ", book.attributes?.bookId);
      router.push(`/purchaseBook`, { book: book.attributes });
      handleClose();
      setLoading(false); // End loading state
    }, 1000);
  };

  // if subscribed the plan, no need purchase, add it to the list
  const onAddBook = async (book) => {
    try {
      setLoading(true); // Start loading state
      await apiRequest("add-book", "POST", {
        bookId: book.attributes.bookId,
      });
      setLoading(false); // End loading state
      setSnackbar({
        open: true,
        message: "Book successfully added!",
        severity: "success",
      });
    } catch (error) {
      setLoading(false); // End loading state
      setSnackbar({
        open: true,
        message: "Error occurred while adding the book.",
        severity: "error",
      });
    }
  };

  return (
    <>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar((prev) => ({ ...prev, open: false }))}
      >
        <Alert
          onClose={() => setSnackbar((prev) => ({ ...prev, open: false }))}
          severity={snackbar.severity}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
      <Backdrop
        className={classes.backdrop}
        open={loading || (!viewOnly && !auth.user)}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Grid container spacing={4}>
        {books.map((book) => (
          <Grid item key={book.id} xs={12} sm={6} md={4}>
            <Box
              className="book-container"
              sx={{
                position: "relative",
                cursor: "pointer",
                overflow: "hidden",
                transition: "transform 0.3s",
                borderRadius: "15px",
              }}
              onMouseEnter={() => setSelectedBook(book)}
            >
              <img
                height="250"
                width="100%"
                src={
                  config.imageUrl + book.attributes.cover.data.attributes.url
                }
                alt={book.attributes.title}
                style={{ objectFit: "cover", borderRadius: "15px" }}
              />
              {selectedBook === book && (
                <Box className={classes.boxStyle}>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Box sx={{ mr: 1 }}>
                      {/* Wrapper with margin */}
                      <Tooltip title="View Book Details">
                        <Button
                          variant="outlined"
                          onClick={() => handleOpen(book)}
                        >
                          Details
                        </Button>
                      </Tooltip>
                    </Box>

                    {!viewOnly && // Existing condition
                      (auth?.user?.purchasedBooks?.includes(
                        book.attributes?.bookId
                      ) ? (
                        <Typography>You own it</Typography>
                      ) : subBooks.includes(
                          book.attributes?.bookId
                        ) ? (
                        <Typography>Already added</Typography>
                      ) : book.attributes.inPlan && !isSubscribed(auth.user) ? (
                        <Typography>Member only</Typography>
                      ) : isSubscribed(auth.user) && book.attributes.inPlan ? (
                        <Box>
                          <Tooltip title="Add Book">
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => onAddBook(book)}
                            >
                              Add
                            </Button>
                          </Tooltip>
                        </Box>
                      ) : (
                        <Box>
                          <Tooltip title="Purchase Book">
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => onPurchase(book)}
                              startIcon={
                                loading ? <CircularProgress size={20} /> : null
                              }
                              disabled={loading}
                            >
                              Purchase
                            </Button>
                          </Tooltip>
                        </Box>
                      ))}
                  </Box>
                </Box>
              )}
            </Box>
          </Grid>
        ))}
      </Grid>

      {/* Book Details Dialog */}
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
        {selectedBook && (
          <>
            <DialogTitle>
              <Typography variant="h5" style={{ fontWeight: "bold" }}>
                {selectedBook.attributes.title}
              </Typography>
            </DialogTitle>
            <DialogContent>
              <img
                height="250"
                width="100%"
                src={
                  config.imageUrl +
                  selectedBook.attributes.cover.data.attributes.url
                }
                alt={selectedBook.attributes.title}
                style={{ objectFit: "cover", marginBottom: "20px" }}
              />
              <Typography gutterBottom>
                {selectedBook.attributes.description}
              </Typography>
              <Typography gutterBottom>
                <strong>Author:</strong> {selectedBook.attributes.author}
              </Typography>
              <Typography gutterBottom>
                <strong>Age Group:</strong> {selectedBook.attributes.age_group}
              </Typography>
              {!viewOnly && // Existing condition
                (auth?.user?.purchasedBooks?.includes(
                  selectedBook.attributes?.bookId
                ) ? (
                  <Typography>You own it</Typography>
                ) : subBooks.includes(
                    selectedBook.attributes?.bookId
                  ) ? (
                  <Typography>Already added</Typography>
                ) : selectedBook.attributes.inPlan && !isSubscribed(auth.user) ? (
                  <Typography>Member only</Typography>
                ) : isSubscribed(auth.user) ? (
                  <Box>
                    <Tooltip title="Add Book">
                      <Button
                        variant="contained"
                        color="primary"
                        disabled={loading}
                        onClick={() => onAddBook(selectedBook)}
                      >
                        {loading ? <CircularProgress size={20} /> : "Add"}
                      </Button>
                    </Tooltip>
                  </Box>
                ) : (
                  <Box>
                    <Tooltip title="Purchase Book">
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => onPurchase(selectedBook)}
                        startIcon={
                          loading ? <CircularProgress size={20} /> : null
                        }
                        disabled={loading}
                      >
                        Purchase
                      </Button>
                    </Tooltip>
                  </Box>
                ))}
            </DialogContent>
          </>
        )}
      </Dialog>
    </>
  );
};

export default BooksGrid;
