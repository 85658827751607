import React from "react";
import Meta from "./../components/Meta";
import HeroSection from "./../components/HeroSection";
import NewsletterSection from "./../components/NewsletterSection";
import BooksSection from "./../components/BooksSection";
import HeroSectionCarousal from "../components/HeroSectionCarousal";

function IndexPage(props) {
  
  return (
    <>
      <Meta />
      <BooksSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Our Books"
        subtitle="Empowering the Next Generation: Uniting Educators and Scientists to Accelerate Your Child's Language Development! Stay Tuned - We're Working on More Books!"
        showNameField={true}
        viewOnly={true}
      />
      <HeroSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Speak, Learn, Grow by Talking to a Book"
        subtitle="Adding 1:1 coached reading to children’s books, enhancing language and literacy skills for parents"
        image="https://pcm-mobile-app-books.s3.us-east-1.amazonaws.com/web-res/images/demo.jpg"
        buttonText="Explore our books"
        buttonColor="primary"
        buttonPath="/books"
      />
      <NewsletterSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Stay in the know"
        subtitle="Receive our latest articles and feature updates"
        buttonText="Subscribe"
        buttonColor="primary"
        inputPlaceholder="Enter your email"
        subscribedMessage="You are now subscribed!"
      />
    </>
  );
}

export default IndexPage;
