import React from "react";
import Meta from "./../components/Meta";
import HeroSection2 from "./../components/HeroSection2";
import TeamBiosSection from "./../components/TeamBiosSection";

function PurchaseBookSuccessPage(props) {
  return (
    <>
      <Meta title="Purchase a book successfully" description="" />
      <HeroSection2
        bgColor="primary"
        size="large"
        bgImage="https://purrbook-store-web.s3.us-east-1.amazonaws.com/images/about.png"
        bgImageOpacity={0.5}
        title="The book is puchased, you can open your app to read it now"
        subtitle=""
      />
    </>
  );
}

export default PurchaseBookSuccessPage;
