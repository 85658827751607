import React from 'react';
import {remark} from 'remark';
import remarkReact from 'remark-react';

export function markdownToReact(markdownContent) {
    const result = remark()
        .use(remarkReact, { createElement: React.createElement })
        .processSync(markdownContent);

    return result.result;
}