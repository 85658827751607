import React, { useEffect, useState } from "react";
import Container from "@material-ui/core/Container";
import { Box, Button } from "@material-ui/core";
import Section from "./Section";
import { useRouter } from "./../util/router";
import SectionHeader from "./SectionHeader";
import BooksGrid from "./BooksGrid";

const config = {
  apiUrl: process.env.REACT_APP_PCM_STORE_API_URL,
  apiToken: process.env.REACT_APP_PCM_STORE_API_TOKEN,
};

function BookSection(props) {
  const [books, setBooks] = useState({ data: [] });
  const router = useRouter();

  useEffect(() => {
    const fetchBooks = async () => {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "bearer " + config.apiToken,
        },
        redirect: "follow",
      };

      try {
        const response = await fetch(
          config.apiUrl + "/books?populate=cover",
          requestOptions
        );
        const data = await response.json();
        setBooks(data);
      } catch (error) {
        console.error("Fetch Error:", error.message);
        setBooks({ data: [] });
      }
    };

    fetchBooks();
  }, []);

  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container maxWidth="md">
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={4}
          textAlign="center"
        />
        {props.viewOnly && (
          <Box display="flex" justifyContent="center" my={4}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => router.push("/books")}
            >
              Add books to your account
            </Button>
          </Box>
        )}
        <BooksGrid books={books?.data || []} viewOnly={props.viewOnly} />
      </Container>
    </Section>
  );
}

export default BookSection;
