import React, { useEffect, useState } from "react";
import Meta from "./../components/Meta"; // If you still use this
import ContentCardsSection from "./../components/ContentCardsSection"; // If you still use this
import BooksSection from "./../components/BooksSection";
import CustomerServiceSection from "./../components/CustomerServiceSection";
import { requireAuth } from "./../util/auth";

const BooksPage = () => {

  return (
    <>
    <Meta title="Our Books" />
    <BooksSection
      bgColor="default"
      size="medium"
      bgImage=""
      bgImageOpacity={1}
      title="Our Books"
      subtitle="more are coming"
      showNameField={true}
      viewOnly={false}
    />
    <CustomerServiceSection/>
  </>
  );
};

export default requireAuth(BooksPage);