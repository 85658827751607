import React from "react";
import Meta from "./../components/Meta";
import HeroSection2 from "./../components/HeroSection2";
import TeamBiosSection from "./../components/TeamBiosSection";

function AboutPage(props) {
  return (
    <>
      <Meta title="About" description="Learn about our company and team" />
      <HeroSection2
        bgColor="primary"
        size="large"
        bgImage="https://purrbook-store-web.s3.us-east-1.amazonaws.com/images/about.png"
        bgImageOpacity={0.5}
        title="Our mission: Transforming the age-old tradition of storytelling, equipping adults with tools to inspire and educate our youth."
        subtitle=""
      />
    </>
  );
}

export default AboutPage;
