import React from "react";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import Carousel from 'react-material-ui-carousel';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  carouselItem: {
    height: 650, // Adjust the height as needed
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.background.paper,
    cursor: 'pointer', // Change cursor to indicate it's clickable
    // Add more styling as needed
  },
  image: {
    width: '100%',
    height: 'auto',
    // Add more styling as needed
  },
}));

function HeroSectionCarousal(props) {
  const classes = useStyles();

  // Function to handle click event
  const handleCarouselItemClick = (url) => {
    window.location.href = url; // Redirect to the URL
  };

  const items = props.images.map((item, i) => (
    <Paper
      key={i}
      className={classes.carouselItem}
      onClick={() => handleCarouselItemClick(item.url)} // Adding click handler
    >
      <img src={item.src} alt={`Slide ${i}`} className={classes.image}/>
    </Paper>
  ));

  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <Box textAlign="center">
          <SectionHeader
            title={props.title}
            subtitle={props.subtitle}
            size={4}
          />
          <Carousel>
            {items}
          </Carousel>
        </Box>
      </Container>
    </Section>
  );
}

export default HeroSectionCarousal;
