//Purchase book page
import React, { useEffect, useState } from "react";
import Alert from "@material-ui/lab/Alert";
import Meta from "./../components/Meta";
import PageLoader from "./../components/PageLoader";
import { useAuth, requireAuth } from "./../util/auth";
import { useLocation } from "./../util/router";
import { redirectToBookCheckout } from "./../util/stripe";

const config = {
    env: process.env.REACT_APP_VERCEL_ENV, // NOT NODE_ENV
}

function PurchaseBookPage(props) {
    const [formAlert, setFormAlert] = useState();

    const { state } = useLocation();
    const { bookId, priceId, previewPriceId } = state?.book; //Use priceId in production, previewPriceId in sandbox

    let usePriceId = previewPriceId;

    

    if (config.env === 'production') {
        usePriceId = priceId;
    }

    console.log(usePriceId);

    useEffect(() => {
        // Check to see if this is a redirect back from Checkout
        const alreadyPurchased = false;

        if (alreadyPurchased) {
            setFormAlert({
                type: "warning",
                message: "you already purchased this book.",
            });
        } else {
            // Otherwise go to checkout
            redirectToBookCheckout(bookId, usePriceId).catch((error) => {
                setFormAlert({
                    type: "error",
                    message: error.message,
                });
            });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Meta title="Purchase a book" />
            <PageLoader>
                {formAlert && (
                    <Alert severity={formAlert.type} style={{ maxWidth: "500px" }}>
                        {formAlert.message}
                    </Alert>
                )}
            </PageLoader>
        </>
    );
}

export default requireAuth(PurchaseBookPage);
