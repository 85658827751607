import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import {
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useForm } from "react-hook-form";
import { useAuth } from "./../util/auth";
import { apiRequest } from "./../util/util";

function SettingsGeneral(props) {
  const auth = useAuth();
  const [pending, setPending] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [deleteConfirmText, setDeleteConfirmText] = useState("");

  const { register, handleSubmit, errors } = useForm();

  const onSubmit = (data) => {
    // Show pending indicator
    setPending(true);

    return auth
      .updateProfile(data)
      .then(() => {
        // Set success status
        props.onStatus({
          type: "success",
          message: "Your profile has been updated",
        });
      })
      .catch((error) => {
        if (error.code === "auth/requires-recent-login") {
          props.onStatus({
            type: "requires-recent-login",
            // Resubmit after reauth flow
            callback: () => onSubmit(data),
          });
        } else {
          // Set error status
          props.onStatus({
            type: "error",
            message: error.message,
          });
        }
      })
      .finally(() => {
        // Hide pending indicator
        setPending(false);
      });
  };

  const handleDeleteClick = () => {
    setShowDeleteDialog(true);
  };

  const handleConfirmDelete = async () => {
    if (deleteConfirmText === "delete") {
      await apiRequest("delete-user", "POST");
      auth.signout();
      alert("Your account and data are deleted.");
    } else {
      alert('Please type "delete" to confirm');
    }
  };

  return (
    <Grid>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={12}>
            <TextField
              variant="outlined"
              type="text"
              label="Name"
              name="name"
              placeholder="Name"
              defaultValue={auth.user.name}
              error={errors.name ? true : false}
              helperText={errors.name && errors.name.message}
              fullWidth={true}
              inputRef={register({
                required: "Please enter your name",
              })}
            />
          </Grid>
          <Grid item={true} xs={12}>
            <TextField
              variant="outlined"
              type="email"
              label="Email"
              name="email"
              placeholder="user@example.com"
              defaultValue={auth.user.email}
              error={errors.email ? true : false}
              helperText={errors.email && errors.email.message}
              fullWidth={true}
              inputRef={register({
                required: "Please enter your email",
              })}
            />
          </Grid>
          <Grid item={true} xs={12}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              type="submit"
              disabled={pending}
              fullWidth={true}
            >
              {!pending && <span>Save</span>}

              {pending && <CircularProgress size={28} />}
            </Button>
          </Grid>
        </Grid>
      </form>
      <Divider component="div" role="presentation"></Divider>
      <Grid
        container
        direction="column"
        alignItems="center"
        style={{ marginTop: 20 }}
      >
        <Grid item>
          <Button
            variant="contained"
            color="secondary" // Making button red
            size="small"
            onClick={handleDeleteClick}
            disabled={pending}
          >
            {!pending && <span>Delete account</span>}
            {pending && <CircularProgress size={28} />}
          </Button>
        </Grid>

        <Grid item style={{ marginTop: 10 }}>
          <Typography
            variant="body2"
            color="error"
            style={{ textAlign: "center" }}
          >
            This action is permanent.
          </Typography>
        </Grid>

        <Dialog
          open={showDeleteDialog}
          onClose={() => setShowDeleteDialog(false)}
        >
          <DialogTitle>Confirm Delete</DialogTitle>
          <DialogContent>
            <TextField
              value={deleteConfirmText}
              onChange={(e) => setDeleteConfirmText(e.target.value)}
              placeholder='Type "delete" to confirm'
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShowDeleteDialog(false)} color="primary">
              Cancel
            </Button>
            <Button onClick={handleConfirmDelete} color="primary">
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    </Grid>
  );
}

export default SettingsGeneral;
