import React, { useEffect, useState } from "react";
import PageLoader from "./PageLoader";
import { useAuth } from "./../util/auth";
import { useRouter } from "./../util/router";
import { redirectToBilling } from "./../util/stripe";
import { Box, Typography, Link } from "@mui/material";

function SettingsBilling(props) {
  const router = useRouter();
  const auth = useAuth();
  const [loading, setLoading] = useState(true);
  const [isAppleIAP, setIsAppleIAP] = useState(false);

  useEffect(() => {
    console.log(auth.user);
    if (auth.user?.iapSubscriptionStatus === "active") {
      setLoading(false);
      setIsAppleIAP(true);
    } else {
      if (auth.user.planIsActive) {
        // If user has an active plan then
        // take them to Stripe billing
        redirectToBilling().catch((error) => {
          setLoading(false);
          props.onStatus({
            type: "error",
            message: error.message,
          });
        });
      } else {
        // Otherwise go to pricing so they can
        // purchase a plan
        router.replace("/pricing");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {loading && <PageLoader height={50} />}
      {isAppleIAP && (
        <Box p={3} bgcolor="info.main" color="info.contrastText">
          <Typography variant="body1">
            You're subscribed through Apple's In-App Purchases. To manage your
            subscription or view bills, please use your Apple device. Need more
            info? Visit{" "}
            <Link
              href="https://support.apple.com/billing"
              color="inherit"
              target="_blank"
              rel="noopener noreferrer"
            >
              Apple Support
            </Link>
            .
          </Typography>
        </Box>
      )}
    </>
  );
}

export default SettingsBilling;
