import React from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import Section from "./Section";
import SectionHeader from "./SectionHeader";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: `0 ${theme.spacing(3)}px`,
  },
}));

function CustomerServiceSection(props) {
  const classes = useStyles();

  return (
    <Section bgColor={props.bgColor}>
      <Container className={classes.container}>
        <Grid container={true} alignItems="center" justify="center" spacing={6}>
          <Grid container={true} item={true} direction="column" xs={12} md={6} alignItems="center" justify="center">
            <Box textAlign="center">
              <SectionHeader
                title="Customer Service"
                subtitle="including billing, return books"
                size={4}
              />
              <Button
                component="a"
                href="mailto:customer@pajamacatsmedia.com"
                variant="contained"
                size="large"
                color="primary"
              >
                Customer Service
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Section>
  );
}

export default CustomerServiceSection;